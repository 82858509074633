import "./App.css";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';
import { RWebShare } from "react-web-share";

function App() {
  const helmetContext = {};
  const [version] = useState("2.0.34");
  const [appUrl] = useState(`/app/ProVenta.setup.${version}.exe`);

  const download = async (e) => {
    e.preventDefault();
    try {
      document.getElementById("my_download").click();
      await fetchAsync(
        "http://las-api-2-env.eba-pbjprpba.us-east-1.elasticbeanstalk.com/api/dw/set"
      );
    } catch {}
  };
  async function fetchAsync(url) {
    let response = await fetch(url);
    let data = await response.json();
    return data;
  }
  return (
    <HelmetProvider context={helmetContext}>
      <Helmet>
      <title>ProVenta: Software de Facturación e Inventario para Empresas RD📃</title>
        <meta name='description' content='Software de facturación e inventario para República Dominicana. Fácil de usar, personalizable y asequible, cuenta con facturación en la nube, prueba un mes gratis.' />
        <meta name="keywords" content="Fácil de usar, DGII, facturacion, inventario, Software, programa, servicios, windows, descarga, productos, negocios, República Dominicana, santo domingo, punto de venta, asequible, bajo costo, salones de belleza, spa, librerias, farmacias, ferreterias" />
        <meta name="author" content="Isidro Calderon Abreu" />
        <link rel="canonical" href="https://www.proventa.app" />
      </Helmet>
    <div className="App">
      <main className="page-wrapper" id="top">
        <header className="header navbar navbar-expand-lg navbar-dark position-absolute navbar-sticky">
          <div className="container px-3">
            <a href="/" className="navbar-brand pe-3">
              <img
                src="assets/img/logo-bg-black.png"
                width="47"
                alt="descubre proVenta: un sistema de facturación e inventario que ayudara a tu negocio a crecer"
              />
              ProVenta
            </a>
            <div id="navbarNav" className="offcanvas offcanvas-end bg-dark">
              <div className="offcanvas-header border-bottom border-light">
                <h5 className="offcanvas-title text-white">Menu</h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                {/* <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle active" data-bs-toggle="dropdown" aria-current="page">Landings</a>
                  <div className="dropdown-menu dropdown-menu-dark p-0">
                    <div className="d-lg-flex">
                      <div className="mega-dropdown-column d-flex justify-content-center align-items-center rounded-3 rounded-end-0 px-0" style={{margin: "-1px", backgroundColor: "#f3f6ff"}}>
                        <img src="assets/img/landings.jpg" alt="Landings" />
                      </div>
                      <div className="mega-dropdown-column pt-lg-3 pb-lg-4">
                        <ul className="list-unstyled mb-0">
                          <li><a href="index.html" className="dropdown-item">Template Intro Page</a></li>
                          <li><a href="landing-mobile-app-showcase-v1.html" className="dropdown-item">Mobile App Showcase v.1</a></li>
                          <li><a href="landing-mobile-app-showcase-v2.html" className="dropdown-item">Mobile App Showcase v.2</a></li>
                          <li><a href="landing-product.html" className="dropdown-item d-flex align-items-center">Product Landing</a></li>
                          <li><a href="landing-startup.html" className="dropdown-item d-flex align-items-center">Startup</a></li>
                          <li><a href="landing-saas-v1.html" className="dropdown-item">SaaS v.1</a></li>
                          <li><a href="landing-saas-v2.html" className="dropdown-item">SaaS v.2</a></li>
                          <li><a href="landing-saas-v3.html" className="dropdown-item">SaaS v.3</a></li>
                          <li><a href="landing-saas-v4.html" className="dropdown-item">SaaS v.4<span className="badge bg-success ms-2">New</span></a></li>
                        </ul>
                      </div>
                      <div className="mega-dropdown-column pt-lg-3 pb-lg-4">
                        <ul className="list-unstyled mb-0">
                          <li><a href="landing-financial.html" className="dropdown-item">Financial Consulting</a></li>
                          <li><a href="landing-online-courses.html" className="dropdown-item">Online Courses</a></li>
                          <li><a href="landing-medical.html" className="dropdown-item">Medical</a></li>
                          <li><a href="landing-software-dev-agency-v1.html" className="dropdown-item">Software Dev Agency v.1</a></li>
                          <li><a href="landing-software-dev-agency-v2.html" className="dropdown-item">Software Dev Agency v.2<span className="badge bg-success ms-2">New</span></a></li>
                          <li><a href="landing-software-dev-agency-v3.html" className="dropdown-item">Software Dev Agency v.3<span className="badge bg-success ms-2">New</span></a></li>
                          <li><a href="landing-conference.html" className="dropdown-item">Conference</a></li>
                          <li><a href="landing-digital-agency.html" className="dropdown-item">Digital Agency</a></li>
                          <li><a href="landing-blog.html" className="dropdown-item">Blog Homepage</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                  <div className="dropdown-menu dropdown-menu-dark">
                    <div className="d-lg-flex pt-lg-3">
                      <div className="mega-dropdown-column">
                        <h6 className="text-light px-3 mb-2">About</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="about-v1.html" className="dropdown-item py-1">About v.1</a></li>
                          <li><a href="about-v2.html" className="dropdown-item py-1">About v.2</a></li>
                          <li><a href="about-v3.html" className="dropdown-item py-1">About v.3</a></li>
                        </ul>
                        <h6 className="text-light px-3 mb-2">Blog</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="blog-list-with-sidebar.html" className="dropdown-item py-1">List View with Sidebar</a></li>
                          <li><a href="blog-grid-with-sidebar.html" className="dropdown-item py-1">Grid View with Sidebar</a></li>
                          <li><a href="blog-list-no-sidebar.html" className="dropdown-item py-1">List View no Sidebar</a></li>
                          <li><a href="blog-grid-no-sidebar.html" className="dropdown-item py-1">Grid View no Sidebar</a></li>
                          <li><a href="blog-simple-feed.html" className="dropdown-item py-1">Simple Feed</a></li>
                          <li><a href="blog-single.html" className="dropdown-item py-1">Single Post</a></li>
                          <li><a href="blog-podcast.html" className="dropdown-item py-1">Podcast</a></li>
                        </ul>
                      </div>
                      <div className="mega-dropdown-column">
                        <h6 className="text-light px-3 mb-2">Portfolio</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="portfolio-grid.html" className="dropdown-item py-1">Grid View</a></li>
                          <li><a href="portfolio-list.html" className="dropdown-item py-1">List View</a></li>
                          <li><a href="portfolio-slider.html" className="dropdown-item py-1">Slider View</a></li>
                          <li><a href="portfolio-courses.html" className="dropdown-item py-1">Courses</a></li>
                          <li><a href="portfolio-single-project.html" className="dropdown-item py-1">Single Project</a></li>
                          <li><a href="portfolio-single-course.html" className="dropdown-item py-1">Single Course</a></li>
                        </ul>
                        <h6 className="text-light px-3 mb-2">Services</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="services-v1.html" className="dropdown-item py-1">Services v.1</a></li>
                          <li><a href="services-v2.html" className="dropdown-item py-1">Services v.2</a></li>
                          <li><a href="services-single-v1.html" className="dropdown-item py-1">Service Details v.1</a></li>
                          <li><a href="services-single-v2.html" className="dropdown-item py-1">Service Details v.2</a></li>
                        </ul>
                      </div>
                      <div className="mega-dropdown-column">
                        <h6 className="text-light px-3 mb-2">Pricing</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="pricing.html" className="dropdown-item py-1">Pricing Page</a></li>
                        </ul>
                        <h6 className="text-light px-3 mb-2">Contacts</h6>
                        <ul className="list-unstyled mb-3">
                          <li><a href="contacts-v1.html" className="dropdown-item py-1">Contacts v.1</a></li>
                          <li><a href="contacts-v2.html" className="dropdown-item py-1">Contacts v.2</a></li>
                          <li><a href="contacts-v3.html" className="dropdown-item py-1">Contacts v.3</a></li>
                        </ul>
                        <h6 className="text-light px-3 mb-2">Specialty</h6>
                        <ul className="list-unstyled">
                          <li><a href="404-v1.html" className="dropdown-item py-1">404 Error v.1</a></li>
                          <li><a href="404-v2.html" className="dropdown-item py-1">404 Error v.2</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Account</a>
                  <ul className="dropdown-menu dropdown-menu-dark">
                    <li><a href="account-details.html" className="dropdown-item">Account Details</a></li>
                    <li><a href="account-security.html" className="dropdown-item">Security</a></li>
                    <li><a href="account-notifications.html" className="dropdown-item">Notifications</a></li>
                    <li><a href="account-messages.html" className="dropdown-item">Messages</a></li>
                    <li><a href="account-saved-items.html" className="dropdown-item">Saved Items</a></li>
                    <li><a href="account-collections.html" className="dropdown-item">My Collections</a></li>
                    <li><a href="account-payment.html" className="dropdown-item">Payment Details</a></li>
                    <li><a href="account-signin.html" className="dropdown-item">Sign In</a></li>
                    <li><a href="account-signup.html" className="dropdown-item">Sign Up</a></li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="components/typography.html" className="nav-link">UI Kit</a>
                </li>
                <li className="nav-item">
                  <a href="docs/getting-started.html" className="nav-link">Docs</a>
                </li>
              </ul>*/}
              </div>
              <div className="offcanvas-header border-top border-light">
                <a href="/" className="btn btn-primary w-100">
                  <i className="bx bxl-whatsapp fs-4 me-2"></i>
                  &nbsp; (809) 787-4963
                </a>
              </div>
            </div>
            {/*
            <div className="dark-mode pe-lg-1 ms-auto me-4">
              <div
                className="form-check form-switch mode-switch"
                data-bs-toggle="mode"
              >
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="theme-mode"
                />
                <label
                  className="form-check-label d-none d-sm-block"
                  for="theme-mode"
                >
                  Light
                </label>
                <label
                  className="form-check-label d-none d-sm-block"
                  for="theme-mode"
                >
                  Dark
                </label>
              </div>
            </div>
            */}
            <button
              type="button"
              className="navbar-toggler"
              data-bs-toggle="offcanvas"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a
              href="https://docs.proventa.app/instalacion"
              className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
            >
              <i className="bx bx-windows fs-5 me-1"></i>
              ¿Como instalar ProVenta?
            </a>
            &nbsp;&nbsp;
            <a
              href="/"
              className="btn btn-primary btn-sm fs-sm rounded d-none d-lg-inline-flex"
            >
              <i className="bx bxl-whatsapp fs-5 me-1"></i>
              (809) 787-4963
            </a>
          </div>
        </header>
        <section
          className="dark-mode bg-dark bg-size-cover bg-repeat-0 bg-position-center position-relative overflow-hidden py-5 mb-4"
          style={{
            backgroundImage: "url(assets/img/landing/saas-3/hero/hero-bg.jpg)",
          }}
        >
          <div className="container position-relative zindex-2 pt-5 pb-md-2 pb-lg-4 pb-xl-5">
            <div className="row pt-3 pb-2 py-md-4">
              <div className="col-xl-5 col-md-6 pt-lg-5 text-center text-md-start mb-4 mb-md-0">
                <h1 className="display-3 pb-2 pb-sm-3">
                  Facturación para PyMEs y Comercios en General, Ahora Más Fácil
                  que Nunca.
                </h1>
                <p className="fs-lg d-md-none d-xl-block pb-2 pb-md-0 mb-4 mb-md-5">
                  Optimiza tu Gestión con Nuestra Aplicación para Windows
                </p>
                <div className="row">
                <div className="d-flex justify-content-center justify-content-md-start justify-content-start pb-2 pt-lg-2 pt-xl-0">
                  <a
                    id="my_download"
                    href={appUrl}
                    download
                    style={{ display: "none" }}
                  >
                    x
                  </a>
                  <a
                    href={"/"}
                    onClick={async (e) => download(e)}
                    style={{ width: "100%"}}  
                    className="btn btn-lg btn-primary shadow-primary me-3 me-sm-4"
                  >
                    <i className="bx bxl-windows fs-1 lh-1 me-1"></i>
                    Descargar para windows <br />v{version}
                  </a>
                  <RWebShare
                    data={{
                      text: "Compartir",
                      title: "Comparte ProVenta con un amigo",
                      url: "http://proventa.app",
                      closeText: "Cerrar",
                    }}
                  >
                    <button style={{ width: "100%"}}  className="btn btn-lg btn-outline-secondary">
                      <i className="bx bx-share-alt fs-3 lh-1 me-1"></i>
                      Compartir
                    </button>
                  </RWebShare>
  
                </div>
                <div className="d-flex justify-content-center justify-content-md-start justify-content-start pb-2 pt-lg-2 pt-xl-0">
                <a style={{ width: "100%"}} href="https://docs.proventa.app" className="btn btn-lg btn-outline-secondary mt-3">
                    <i className="bx bx-book fs-3 lh-1 me-1"></i>
                    Documentación en linea
                  </a>
                  </div>
                  </div>
                {/*<div className="d-flex align-items-center justify-content-center justify-content-md-start text-start pt-4 pt-lg-5 mt-xxl-5">
                <div className="d-flex me-3">
                  <div className="d-flex align-items-center justify-content-center bg-light rounded-circle" style={{width: "52px", height: "52px"}}>
                    <img src="assets/img/avatar/14.jpg" className="rounded-circle" width="48" alt="Avatar" />
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3" style={{width: "52px", height: "52px"}}>
                    <img src="assets/img/avatar/08.jpg" className="rounded-circle" width="48" alt="Avatar" />
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-light rounded-circle ms-n3" style={{width: "52px", height: "52px"}}>
                    <img src="assets/img/avatar/15.jpg" className="rounded-circle" width="48" alt="Avatar" />
                  </div>
                </div>
                <div className="text-light"><strong>400k+</strong> users already with us</div>
            </div>*/}
              </div>
              <div className="col-xl-7 col-md-6 d-md-flex justify-content-end">
                <div
                  className="parallax mx-auto ms-md-0 me-md-n5"
                  style={{ MaxWidth: "675px" }}
                >
                  <div className="parallax-layer zindex-2" data-depth="0.1">
                    <img
                      src="assets/img/facturacion_para_pymes_de_productos_y_servicios.png"
                      alt="Factura de venta para el cliente."
                    />
                  </div>
                  <div className="parallax-layer zindex-3" data-depth="0.25">
                    <img
                      src="assets/img/facturacion_para_pymes_de_productos_y_servicios.png"
                      alt="pantalla principal de facturación"
                    />
                  </div>
                  <div className="parallax-layer" data-depth="-0.15">
                    <img
                      src="assets/img/facturacion_para_pymes_de_productos_y_servicios.png"
                      alt="la mejor relación entre funcionalidad y bajo costo."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*
      <section className="container border-bottom-lg pt-sm-2">
        <div className="swiper mx-n2" data-swiper-options='{
          "slidesPerView": 2,
          "pagination": {
            "el": ".swiper-pagination",
            "clickable": true
          },
          "breakpoints": {
            "500": {
              "slidesPerView": 3,
              "spaceBetween": 8
            },
            "650": {
              "slidesPerView": 4,
              "spaceBetween": 8
            },
            "850": {
              "slidesPerView": 5,
              "spaceBetween": 8
            },
            "992": {
              "slidesPerView": 6,
              "spaceBetween": 8
            }
          }
        }'>
          <div className="swiper-wrapper">


            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/01.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>


            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/02.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>

            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/03.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>


            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/04.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>


            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/05.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>


            <div className="swiper-slide py-2">
              <a href="#" className="px-2 mx-2">
                <img src="assets/img/brands/06.svg" className="d-block mx-auto my-2" width="155" alt="Brand" />
              </a>
            </div>
          </div>

          <div className="swiper-pagination position-relative pt-3"></div>
        </div>
      </section>
*/}
        <hr />
        <section className="container py-5 my-lg-4 my-xl-5">
          <h2
            className="h1 text-center mx-auto mt-n2 mt-sm-0 pt-md-2"
            style={{ maxWidth: "930px" }}
          >
            ¡La Solución de Facturación Disponible para Toda la República
            Dominicana!
          </h2>
          <ul className="list-unstyled d-flex flex-wrap justify-content-center mb-5">
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Mantén tu inventario organizado
                <strong className="text-primary ms-1"> </strong>
              </span>
            </li>
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Facturación de productos y servicios
                <strong className="text-primary ms-1"> </strong>
              </span>
            </li>
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Interacción cercana con tus clientes{" "}
                <strong className="text-primary ms-1"> </strong>
              </span>
            </li>
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Comisión por ventas a empleados
                <strong className="text-primary mx-1"> </strong>{" "}
              </span>
            </li>
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Proveedores <strong className="text-primary mx-1"> </strong>
              </span>
            </li>
            <li className="d-flex fs-xl mx-3 mt-2 mt-sm-3">
              <i className="bx bx-check lead text-primary mt-1 me-2"></i>
              <span>
                Reportes de ventas{" "}
                <strong className="text-primary mx-1"> </strong>
              </span>
            </li>
          </ul>

          <div className="position-relative px-4 px-sm-5 mb-3 mb-md-4">
            <img
              src="assets/img/pantalla_facturacion.png"
              width="1079"
              className="d-block mx-auto"
              style={{
                borderRadius: "1.5rem",
                boxShadow: "0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2)",
              }}
              alt="consulta cliente en la DGII con su rnc, un software fácil de usar."
            />
            <div
              className="position-absolute w-100"
              style={{ left: "0", bottom: "13%", paddingRight: "74.33%" }}
            >
              <img
                src="assets/img/print_preview_ticket.png"
                width="308"
                className="rellax d-block"
                style={{
                  borderRadius: ".75rem",
                  boxShadow: "0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2)",
                }}
                alt="vista previa de factura y generación de pdf."
                data-rellax-percentage="0.5"
                data-rellax-speed="1"
                data-disable-parallax-down="lg"
              />
            </div>
            <div
              className="position-absolute w-100"
              style={{ left: "0", bottom: "12%", paddingRight: "74.16%" }}
            >
              <img
                src="assets/img/print_preview_ticket.png"
                width="310"
                className="rellax d-block"
                style={{
                  borderRadius: ".75rem",
                  boxShadow: "0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2)",
                }}
                alt="envia facturas por email y whatsapp a tu clientes"
                data-rellax-percentage="0.5"
                data-rellax-speed="1"
                data-disable-parallax-down="lg"
              />
            </div>
            <div
              className="position-absolute w-100"
              style={{
                right: "0",
                bottom: "9.4%",
                paddingLeft: "64.83%",
                paddingRight: "5.5%",
              }}
            ></div>
          </div>
        </section>

        <section className="container pb-5 pt-md-2 pt-lg-4 pt-xl-5">
          <h2 className="h1 mb-5">Módulos principales</h2>
          <div className="row pb-md-2 pb-lg-4 pb-xl-5 mb-xxl-2">
            <div className="col-lg-7 col-md-7">
              <div className="table-responsive border-top mb-0">
                <table className="table align-middle">
                  <tbody>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Gestión de inventario
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Historial de ventas</li>
                            <li>Alertas por control de existencia</li>
                            <li>Facturación con o sin stock</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Manejo de clientes
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Categoriza tus clientes</li>
                            <li>Envío de facturas por email</li>
                            <li>Envío de facturas por WhatsApp</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Proveedores
                      </th>
                      <td className="pe-0">
                        <span className="d-inline-block my-4 py-1">
                          Mantén tu base de proveedores actualizada
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Cotizaciones
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Crea tus cotizaciones en facturas</li>
                            <li>Envío de cotizaciones por email</li>
                            <li>Envío de cotizaciones por WhatsApp</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Empleados
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Pago de nómina​</li>
                            <li>Comisión por venta de productos</li>
                            <li>Comisión por servicios</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Usuarios
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Multiples cajeros</li>
                            <li>Restricción por roles</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Impresión
                      </th>
                      <td className="pe-0">
                        <span className="d-inline-block my-4 py-1">
                          Imprime tus facturas pequeñas (punto de venta) o
                          normal
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" className="ps-0 h5">
                        Nómina​
                      </th>
                      <td className="pe-0">
                        <div className="d-inline-block my-4 py-1">
                          <ul className="list-unstyled mb-0">
                            <li>Volante de pago</li>
                            <li>AFP</li>
                            <li>ARS</li>
                            <li>Deducciones</li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-5">
              <div className="row row-cols-2 row-cols-md-1">
                <div className="col mb-4">
                  <img
                    src="assets/img/inventario.png"
                    alt="gestiona tu inventario."
                  />
                </div>
                <div className="col mb-4">
                  <img
                    src="assets/img/clientes.png"
                    alt="solida base de datos de tus clientes."
                  />
                </div>
                <div className="col mb-4">
                  <img
                    src="assets/img/productos.png"
                    alt="Lista de productos en un almacén."
                  />
                </div>
                <div className="col mb-4">
                  <img
                    src="assets/img/dashboard.png"
                    alt="visualiza reporte, mantente actualizado con las ventas."
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*
      <section className="container pb-5 mb-md-2 mb-lg-4 mb-xl-5">
        <div className="row align-items-center pt-2 pb-3">

          <div className="col-md-6 col-xl-5 text-center text-md-start mb-5 mb-md-0">
            <h2 className="h1 pb-2 pb-lg-3">Explore Opportunities with Silicon App</h2>
            <p className="pb-2 mb-4 mb-lg-5">In blandit luctus proin mauris a commodo, dolor diam tempus, aenean magna fusce eu. Id porttitor aliquam eget aliquet sagittis eu aut diam ut phasellus sed convallis iaculis neque ultricies convallis sed enim.</p>
            <hr />
            <div className="d-flex justify-content-center justify-content-md-between pt-4 pt-lg-5">
              <div className="mx-3 mx-md-0">
                <div className="display-3 text-dark mb-1">23%</div>
                <span>Nulla venenatis everys</span>
              </div>
              <div className="mx-3 mx-md-0">
                <div className="display-3 text-dark mb-1">132b</div>
                <span>Dictum in marko elementum</span>
              </div>
            </div>
          </div>

  
          <div className="col-md-6 offset-xl-1">
            <div className="parallax ratio ratio-1x1 mx-auto" style={{maxWidth: "550px"}}>
              <div className="parallax-layer position-absolute zindex-2" data-depth="-0.15">
                <img src="assets/img/landing/saas-3/feature-2/avatars.png" alt="Avatar" />
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.1">
                <div className="rounded-circle bg-primary" style={{width: "70%", height: "70%", opacity: ".06"}}></div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.2">
                <div className="rounded-circle bg-primary" style={{width: "55%", height: "55%", opacity: ".06"}}></div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 zindex-3" data-depth="0.4">
                <div className="d-flex justify-content-center align-items-center rounded-circle bg-primary" style={{width: "93px", height: "93px", boxShadow: "0 .1875rem 1.875rem -.125rem rgba(99,102,241, .95)"}}>
                  <span className="h5 text-light mb-0">400K+</span>
                </div>
              </div>
              <div className="parallax-layer d-flex justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100" data-depth="0.3">
                <div className="rounded-circle bg-primary" style={{width: "40%", height: "40%", opacity: ".06"}}></div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="container pt-2 pt-sm-4 pb-5 mb-md-2 mb-lg-4 mb-xl-5">
        <div className="row align-items-center mb-2 pb-sm-3 pb-md-4">

          <div className="col-md-5 col-lg-6 col-xl-5 offset-xl-1 order-md-2 text-center text-md-start mb-5 mb-md-0">
            <h2 className="h1 pb-2 pb-lg-3">Make Your Life a lot Easier with Crypto Bank</h2>
            <p className="pb-2 mb-4 mb-xl-5">Sociis sit risus id. Sit facilisis dolor fermentum vestibulum arcuulvi maecenas maecenas pharet tincidunt sollicitudin in pellentesque vitae.</p>
            <hr />
            <div className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-md-between pt-3">
              <img src="assets/img/landing/saas-3/feature-3/binance.svg" width="48" className="d-block m-2 mx-md-0" alt="Bitcoin" />
              <img src="assets/img/landing/saas-3/feature-3/ens.svg" width="48" className="d-block m-2 mx-md-0" alt="Ens" />
              <img src="assets/img/landing/saas-3/feature-3/tether.svg" width="48" className="d-block m-2 mx-md-0" alt="Tether" />
              <img src="assets/img/landing/saas-3/feature-3/alqo.svg" width="48" className="d-block m-2 mx-md-0" alt="Alqo" />
              <img src="assets/img/landing/saas-3/feature-3/blockfi.svg" width="48" className="d-block m-2 mx-md-0" alt="BlockFi" />
              <img src="assets/img/landing/saas-3/feature-3/bitcoin.svg" width="48" className="d-block m-2 mx-md-0" alt="Bitcoin" />
            </div>
          </div>

          <div className="col-md-7 col-lg-6 order-md-1">
            <div className="position-relative pt-5" style={{maxWidth: "636px"}}>
              <img src="assets/img/landing/saas-3/feature-3/popup.png" className="rellax position-absolute top-0 mt-n5" alt="Card" data-rellax-percentage="0.5" data-rellax-speed="1" data-disable-parallax-down="lg" />
              <img src="assets/img/landing/saas-3/feature-3/dashboard.jpg" className="d-block rounded-3 mt-sm-4 mt-md-0 mt-lg-4" alt="Dashboard" style={{boxShadow: "0 1.875rem 7.5rem -.625rem rgba(124,125,152, .2)"}} />
            </div>
          </div>
        </div>
      </section>
*/}

        <section className="container">
          <div
            className="text-center pb-4 pb-md-0 mb-2 mb-md-5 mx-auto"
            style={{ maxWidth: "530px" }}
          >
            <h2 className="h1">¿Cómo empezar?</h2>
            <p className="mb-0">¡Sigue estos simples pasos!</p>
          </div>

          <div className="steps steps-sm steps-horizontal-md steps-center pb-5 mb-md-2 mb-lg-3">
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">1</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-3">Descarga e instalación </h3>
                <p className="mb-0">
                  Haz clic en descargar y el instalador te guiará
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">2</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-3">Registro de cuenta</h3>
                <p className="mb-0">
                  Solo te pediremos tu nombre, correo y nombre de tu empresa
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">3</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-3">Crea tu primer producto/servicio</h3>
                <p className="mb-0">
                  Para empezar a facturar solo tienes que crear al menos un
                  producto o servicio
                </p>
              </div>
            </div>
            <div className="step">
              <div className="step-number">
                <div className="step-number-inner">4</div>
              </div>
              <div className="step-body">
                <h3 className="h4 mb-3">¿Necesitas ayuda?</h3>
                <p className="mb-0">
                  Si necesitas ayuda o entrenamiento solo escríbenos
                </p>
              </div>
            </div>
          </div>
        </section>
        <br />
        <section className="position-relative dark-mode bg-dark py-5">
          <span className="position-absolute top-0 start-0 w-100 h-100 bg-secondary"></span>
          <div className="position-relative container mt-lg-5 my-md-4 my-3 py-xl-3">
            <h2 className="h1 mb-md-4 mb-3 pb-lg-3 pb-2 text-center">
              Nuestros planes - ¡Precios transparentes para ti!
            </h2>
            <div className="price-switch-wrapper">
              <div className="table-responsive-lg pb-4">
                <div className="d-flex align-items-center">
                  <div
                    className="border rounded-3 rounded-end-0 shadow-sm me-n1"
                    style={{ width: "32%", minWidth: "16rem" }}
                  >
                    <div className="card bg-light h-100 border-0 border-end rounded-end-0 py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-center">
                        <h3 className="mb-2">Emprendedor</h3>
                        <div className="fs-lg pb-4 mb-3">
                          La mejor opción para uso personal o negocios pequeños
                        </div>
                        <div
                          className="display-5 text-dark mb-1"
                          data-monthly-price=""
                        >
                          RD$1,800
                        </div>
                        <div
                          className="d-none display-5 text-dark mb-1"
                          data-annual-price=""
                        >
                          RD$1,800
                        </div>
                        <div className="text-muted mb-5">por mes</div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href={"/"}
                          onClick={async (e) => download(e)}
                          className="btn btn-outline-primary btn-lg"
                        >
                          {" "}
                          <i className="bx bx-cloud-download fs-5 lh-1 me-1"></i>
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="bg-primary position-relative rounded-3 shadow-primary shadow-dark-mode-none zindex-2 p-4"
                    style={{ width: "36%", minWidth: "18rem" }}
                  >
                    <div className="card bg-transparent border-light py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-light text-center">
                        <h3 className="text-light mb-2">Básico</h3>
                        <div className="fs-lg opacity-70 pb-4 mb-3">
                          Ideal para PYME o para comercios en continua evolución
                          y crecimiento
                        </div>
                        <div className="display-5 mb-1" data-monthly-price="">
                          RD$2,300
                        </div>
                        <div
                          className="d-none display-5 mb-1"
                          data-annual-price=""
                        >
                          RD$2,300.00
                        </div>
                        <div className="opacity-50 mb-5">por mes</div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href={"/"}
                          onClick={async (e) => download(e)}
                          className="btn btn-light btn-lg shadow-secondary"
                        >
                          {" "}
                          <i className="bx bx-cloud-download fs-5 lh-1 me-1"></i>
                          Descargar
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="border rounded-3 rounded-start-0 shadow-sm"
                    style={{ width: "32%", minWidth: "16rem" }}
                  >
                    <div className="card bg-light h-100 border-0 rounded-start-0 py-3 py-sm-4 py-lg-5">
                      <div className="card-body text-center">
                        <h3 className="mb-2">Enterprise</h3>
                        <div className="fs-lg pb-4 mb-3">
                          Mejor opción para comercios mas grandes que manejan
                          gran volumen de datos
                        </div>
                        <div
                          className="display-5 text-dark mb-1"
                          data-monthly-price=""
                        >
                          RD$6,500
                        </div>
                        <div
                          className="d-none display-5 text-dark mb-1"
                          data-annual-price=""
                        >
                          RD$6,500
                        </div>
                        <div className="text-muted mb-5">por mes</div>
                      </div>
                      <div className="card-footer border-0 text-center pt-0 pb-4">
                        <a
                          href="/"
                          className="btn btn-outline-primary btn-lg"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="bx bx-phone"></i>&nbsp;Contáctenos
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container py-5 my-md-3 my-lg-5" id="planes">
          <div className="row align-items-center">
            <div className="col-md-4 text-center text-md-start">
              <h2 className="h1 mb-4">Comparación de Planes</h2>
              <p className="fs-lg mb-md-0">
                Elige la opción que tenga más sentido para tu negocio. incluyen
                un mes de prueba gratis sin tarjetas de crédito o pago por
                adelantado.
              </p>
            </div>
            <div className="col-md-8">
              <div className="row row-cols-1 row-cols-md-3">
                <div className="col text-md-center pb-2 mb-4">
                  <h3 className="h5 mb-2 mb-md-3">Emprendedor</h3>
                  <ul className="d-md-none list-unstyled">
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Usuarios:&nbsp;
                      </span>
                      2 incluidos
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Empleados:&nbsp;
                      </span>
                      5
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Soporte técnico:&nbsp;
                      </span>
                      L-V 9AM-5PM
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Imágenes productos&nbsp;
                      </span>
                      1
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Facturas&nbsp;
                      </span>
                      Ilimitadas
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Clientes&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Reportes proyecciones de ventas&nbsp;
                      </span>
                      <i className="bx bx-x lead text-muted"></i>
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Asistencia personalizada;&nbsp;
                      </span>
                      <i className="bx bx-x lead text-muted"></i>
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Ingresos&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Multiples cajeros&nbsp;
                      </span>
                      <i className="bx bx-x lead text-muted"></i>
                    </li>
                  </ul>
                  <button
                    title="Mensualidad"
                    className="btn btn-outline-primary w-100 w-md-auto"
                  >
                    RD$1,800
                  </button>
                </div>
                <div className="col text-md-center pb-2 mb-4">
                  <h3 className="h5 mb-2 mb-md-3">Básico</h3>
                  <ul className="d-md-none list-unstyled">
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Usuarios:&nbsp;
                      </span>
                      5 incluidos
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Empleados&nbsp;
                      </span>
                      10
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Soporte técnico&nbsp;
                      </span>
                      L-S 8AM-10PM
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Imágenes productos&nbsp;
                      </span>
                      3
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Facturas&nbsp;
                      </span>
                      Ilimitadas
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Clientes&nbsp;
                      </span>
                      Ilimitadas
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Reportes proyecciones de ventas&nbsp;
                      </span>
                      <i className="bx bx-check lead text-primary"></i>
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Asistencia personalizada&nbsp;
                      </span>
                      Limitado
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Ingresos&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Multiples cajeros&nbsp;
                      </span>
                      <i className="bx bx-check lead text-primary"></i>
                    </li>
                  </ul>
                  <button
                    title="Mensualidad"
                    className="btn btn-outline-primary w-100 w-md-auto"
                  >
                    RD$2,300
                  </button>
                </div>
                <div className="col text-md-center mb-3">
                  <h3 className="h5 mb-2 mb-md-3">Enterprise</h3>
                  <ul className="d-md-none list-unstyled">
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Usuarios:&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Empleados&nbsp;
                      </span>
                      Ilimitadas
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Soporte técnico&nbsp;
                      </span>
                      24/7
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Imágenes productos&nbsp;
                      </span>
                      10
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Facturas&nbsp;
                      </span>
                      Ilimitadas
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Clientes&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2">
                      <span className="text-dark fw-medium">
                        Reportes proyecciones de ventas&nbsp;
                      </span>
                      <i className="bx bx-check lead text-primary"></i>
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Asistencia personalizada&nbsp;
                      </span>
                      <i className="bx bx-check lead text-primary"></i>
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Ingresos&nbsp;
                      </span>
                      Ilimitados
                    </li>
                    <li className="d-flex align-items-center p-2 bg-secondary">
                      <span className="text-dark fw-medium">
                        Multiple cajeros&nbsp;
                      </span>
                      <i className="bx bx-check lead text-primary"></i>
                    </li>
                  </ul>
                  <button
                    title="Mensualidad"
                    className="btn btn-outline-primary w-100 w-md-auto"
                  >
                    RD$6,500
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-md-block pt-5 mt-2 pb-4">
            <div className="row g-0">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Usuarios
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">2 incluidos</div>
                  <div className="col position-relative bg-primary px-2 py-4">
                    <span className="text-light opacity-80">5 incluidos</span>
                    <span
                      className="position-absolute start-0 bottom-100 w-100 bg-primary rounded-3 rounded-bottom-0"
                      style={{ height: "2rem" }}
                    ></span>
                  </div>
                  <div className="col px-2 py-4">Ilimitados</div>
                </div>
              </div>
            </div>
            <div className="row g-0 bg-secondary rounded-3">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Empleados
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">5</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">10</span>
                  </div>
                  <div className="col px-2 py-4">Ilimitados</div>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Soporte técnico
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">L-V 9AM-5PM</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">L-S 8AM-10PM</span>
                  </div>
                  <div className="col px-2 py-4">24/7</div>
                </div>
              </div>
            </div>
            <div className="row g-0 bg-secondary rounded-3">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Imágenes productos
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">1</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">3</span>
                  </div>
                  <div className="col px-2 py-4">10</div>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Facturas
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">Ilimitadas</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">Ilimitadas</span>
                  </div>
                  <div className="col px-2 py-4">Ilimitadas</div>
                </div>
              </div>
            </div>
            <div className="row g-0 bg-secondary rounded-3">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Clientes
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">Ilimitados</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">Ilimitados</span>
                  </div>
                  <div className="col px-2 py-4">Ilimitados</div>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Reportes proyecciones de ventas
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">
                    <i className="bx bx-x lead text-muted"></i>
                  </div>
                  <div className="col bg-primary px-2 py-4">
                    <i className="bx bx-check lead text-light"></i>
                  </div>
                  <div className="col px-2 py-4">
                    <i className="bx bx-check lead text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 bg-secondary rounded-3">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Asistencia personalizada
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">
                    <i className="bx bx-x lead text-muted"></i>
                  </div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">Limitado</span>
                  </div>
                  <div className="col px-2 py-4">
                    <i className="bx bx-check lead text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Ingresos{" "}
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">Ilimitados</div>
                  <div className="col bg-primary px-2 py-4">
                    <span className="text-light opacity-80">Ilimitados</span>
                  </div>
                  <div className="col px-2 py-4">Ilimitados</div>
                </div>
              </div>
            </div>
            <div className="row g-0 bg-secondary rounded-3">
              <div className="col-md-4 text-dark fw-semibold ps-4 pe-2 py-4">
                Multiples cajeros
              </div>
              <div className="col-md-8 text-center">
                <div className="row row-cols-3 g-0">
                  <div className="col px-2 py-4">
                    <i className="bx bx-x lead text-muted"></i>
                  </div>
                  <div className="col position-relative bg-primary px-2 py-4">
                    <i className="bx bx-check lead text-light"></i>
                    <span
                      className="position-absolute start-0 top-100 w-100 bg-primary rounded-3 rounded-top-0"
                      style={{ height: "2rem" }}
                    ></span>
                  </div>
                  <div className="col px-2 py-4">
                    <i className="bx bx-check lead text-primary"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-secondary py-5">
          <div className="container my-2 py-md-2 py-lg-5">
            <div className="row">
              <div className="col-xl-4 col-md-5 text-center text-md-start pb-2 pb-md-0 mb-4 mb-md-0">
                <h2 className="pb-3 mb-1 mb-lg-2">
                  ¿Tienes preguntas? <br className="d-none d-md-inline" />
                  Estas son las más comunes
                </h2>
                <p className="fs-lg pb-3 mb-2 mb-lg-3">
                  ¿Todavía tiene preguntas sin responder y necesita ponerse en
                  contacto?
                </p>
                <a
                  href="/"
                  onClick={(e) => e.preventDefault()}
                  className="btn btn-primary"
                >
                  <i className="bx bxl-whatsapp fs-4 me-2"></i> (809) 787-4963
                </a>
              </div>
              <div className="col-md-7 offset-xl-1">
                <div className="accordion" id="faq">
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h2 className="accordion-header" id="q1-heading">
                      <button
                        className="accordion-button shadow-none rounded-3 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#q1"
                        aria-expanded="false"
                        aria-controls="q1"
                      >
                        ¿Cómo sé qué plan es el adecuado para mí?
                      </button>
                    </h2>
                    <div
                      id="q1"
                      className="accordion-collapse collapse"
                      aria-labelledby="q1-heading"
                      data-bs-parent="#faq"
                    >
                      <div className="accordion-body pt-0">
                        <p>
                          Al elegir el plan adecuado para ti, es importante
                          considerar tus necesidades específicas y el tamaño de
                          tu negocio. ProVenta ofrece diferentes planes que se
                          adaptan a distintos niveles de operación. Aquí hay
                          algunos factores a tener en cuenta al decidir qué plan
                          es el adecuado para ti:
                          <div>
                            <ul>
                              <li className="mt-4">
                                Tamaño del negocio: Evalúa el tamaño de tu
                                negocio en términos de ventas, número de
                                productos y clientes. Si tienes un negocio
                                pequeño con volúmenes de facturación y niveles
                                de inventario limitados, es posible que un plan
                                básico sea suficiente. Para negocios más grandes
                                con un alto volumen de transacciones y un
                                inventario extenso, es posible que un plan más
                                completo y escalable sea la mejor opción.
                              </li>
                              <li className="mt-4">
                                Funcionalidades requeridas: Revisa las
                                funcionalidades que necesitas para tu negocio.
                                Cada plan de ProVenta ofrece diferentes
                                características y capacidades. Considera si
                                necesitas características adicionales como
                                control de gastos, reportes detallados,
                                integraciones con otros sistemas, entre otros.
                                Evalúa qué funcionalidades son esenciales para
                                tu negocio y busca un plan que las incluya.
                              </li>
                            </ul>
                          </div>
                        </p>
                        <p>
                          Para ayudarte a elegir el plan adecuado, ProVenta
                          proporciona descripciones detalladas de cada plan en
                          nuestro sitio web. Puedes comparar las características
                          y capacidades de cada plan y tomar una decisión
                          informada en función de tus necesidades comerciales.
                        </p>
                        <p>
                          Recuerda que si tienes dificultades para elegir el
                          plan adecuado, nuestro equipo de atención al cliente
                          está disponible para responder tus preguntas y
                          brindarte asesoramiento personalizado. Estaremos
                          encantados de ayudarte a encontrar la mejor opción
                          para tu negocio.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h2 className="accordion-header" id="q2-heading">
                      <button
                        className="accordion-button shadow-none rounded-3 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#q2"
                        aria-expanded="false"
                        aria-controls="q2"
                      >
                        ¿Existen compromisos a largo plazo o tarifas de
                        cancelación?
                      </button>
                    </h2>
                    <div
                      id="q2"
                      className="accordion-collapse collapse"
                      aria-labelledby="q2-heading"
                      data-bs-parent="#faq"
                    >
                      <div className="accordion-body pt-0">
                        <p>
                          No hay compromisos a largo plazo ni pagos por
                          cancelación
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h2 className="accordion-header" id="q3-heading">
                      <button
                        className="accordion-button shadow-none rounded-3 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#q3"
                        aria-expanded="false"
                        aria-controls="q3"
                      >
                        ¿Cómo cancelo mi suscripción?
                      </button>
                    </h2>
                    <div
                      id="q3"
                      className="accordion-collapse collapse"
                      aria-labelledby="q3-heading"
                      data-bs-parent="#faq"
                    >
                      <div className="accordion-body pt-0">
                        <p>
                          Cancelar tu suscripción mensual en ProVenta es un
                          proceso sencillo. Sigue estos pasos:
                        </p>
                        <p className="mb-0">
                          <div>
                            <ol>
                              <li className="mt-1">
                                Inicia sesión en tu cuenta de ProVenta
                                utilizando tus credenciales de acceso.
                              </li>
                              <li className="mt-1">
                                Una vez que hayas iniciado sesión, busca la
                                sección de "Configuración" o "Cuenta" en el menú
                                principal. Puede variar según la interfaz de
                                usuario de ProVenta.
                              </li>
                              <li className="mt-1">
                                Dentro de la sección de configuración o cuenta,
                                busca la opción de "Suscripción" o
                                "Facturación". Haz clic en esa opción para
                                acceder a los detalles de tu suscripción actual.
                              </li>
                              <li className="mt-1">
                                Dentro de los detalles de la suscripción,
                                deberías encontrar la opción para cancelar la
                                suscripción, "Cancelar suscripción" o algo
                                similar. Haz clic en esa opción.
                              </li>
                            </ol>
                          </div>
                        </p>
                        <p>
                          Es importante tener en cuenta que al cancelar tu
                          suscripción mensual, perderás el acceso a las
                          funcionalidades y características de ProVenta una vez
                          finalizado el período de facturación actual.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h2 className="accordion-header" id="q4-heading">
                      <button
                        className="accordion-button shadow-none rounded-3 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#q4"
                        aria-expanded="false"
                        aria-controls="q4"
                      >
                        ¿Qué tipo de entrenamiento o soporte recibo?
                      </button>
                    </h2>
                    <div
                      id="q4"
                      className="accordion-collapse collapse"
                      aria-labelledby="q4-heading"
                      data-bs-parent="#faq"
                    >
                      <div className="accordion-body pt-0">
                        <p>
                          Nuestro objetivo es brindarte el soporte necesario
                          para garantizar tu éxito. Si tienes necesidades
                          específicas de entrenamiento o requieres un nivel más
                          personalizado de soporte estaremos ahí para ti.
                        </p>
                        <p className="mb-0">
                          Recuerda que estamos comprometidos a ayudarte en cada
                          paso.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer py-5">
        <div className="container pt-2 pt-sm-4">
          <div className="row">
            <div className="col-md-6 col-lg-5 col-xl-4 pb-2 pb-sm-3 pb-md-0 mb-4 mb-md-0">
              <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                <img
                  src="assets/img/logo-bg-white.png"
                  alt="gestiona tu negocio de una forma fácil y de bajo costo"
                  width="47"
                />
                ProVenta
              </div>
              <p className="mb-0">
                "Tu solución integral para facturación e inventario. Simplifica
                tu negocio, optimiza tus procesos y aumenta tu productividad con
                nuestra plataforma confiable. Descubre una gestión eficiente y
                potencia tu crecimiento. ¡Únete a miles de clientes satisfechos
                hoy mismo!"
              </p>
            </div>
            <div className="col-md-6 col-xxl-5 offset-lg-1 offset-xl-2 offset-xxl-3">
              <div className="row row-cols-1 row-cols-sm-2">
                <div className="col pb-2 pb-sm-0 mb-4 mb-sm-0">
                  <h3 className="h5 pb-1 pb-sm-2 pb-lg-3">Contáctenos</h3>
                  <ul className="nav flex-column mb-3">
                    <li>
                      <a
                        href="tel:8097874963"
                        className="nav-link fs-lg fw-normal px-0 py-1"
                      >
                        <i className="bx bx-phone-call fs-4 me-2"></i>
                        <i className="bx bxl-whatsapp fs-4 me-2"></i> (809)
                        787-4963
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:ayuda@proventa.app"
                        className="nav-link fs-lg fw-normal px-0 py-1"
                      >
                        <i className="bx bx-envelope fs-4 me-2"></i>
                        ayuda.proventa@gmail.com
                      </a>
                    </li>
                  </ul>
                  <div className="d-flex pt-2 pt-sm-3 pt-md-4">
                    <a
                      href="https://www.facebook.com/sistemaproventa"
                      target="blank"
                      className="btn btn-icon btn-sm btn-secondary btn-facebook rounded-circle me-3"
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/sistemaproventa/"
                      target="blank"
                      className="btn btn-icon btn-sm btn-secondary btn-telegram rounded-circle me-3"
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/isidro-calderon-abreu/"
                      className="btn btn-icon btn-sm btn-secondary btn-linkedin rounded-circle me-3"
                      target="blank"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>

                    <a
                      href="/"
                      className="btn btn-icon btn-sm btn-secondary btn-youtube rounded-circle me-3"
                    >
                      <i className="bx bxl-youtube"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="nav d-block fs-sm pt-5 mb-0">
            <span className="opacity-60">© All rights reserved. Made by </span>
            <a
              className="nav-link d-inline-block p-0"
              href="https://www.linkedin.com/in/isidro-calderon-abreu/"
              target="_blank"
              rel="noreferrer"
            >
              Isidro Calderon A.
            </a>
          </p>
        </div>
      </footer>

      <a href="#top" className="btn-scroll-top" data-scroll>
        <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">
          Top
        </span>
        <i className="btn-scroll-top-icon bx bx-chevron-up"></i>
      </a>
    </div>
    </HelmetProvider>
  );
}

export default App;
